const DEAL_EVENTS_JOB_QUEUES = {
  READ_STATUS_UPDATES: 'deal-events-read-status-updates'
}

const SHOPPING_CART_CURRENCY = {
  /** @type {'EUR'} */
  EUR: 'EUR',
  /** @type {'GBP'} */
  GBP: 'GBP',
  /** @type {'SEK'} */
  SEK: 'SEK'
}

const SHOPPING_CART_CURRENCY_SYMBOLS = {
  /** @type {'€'} */
  EUR: '€',
  /** @type {'£'} */
  GBP: '£',
  /** @type {'kr'} */
  SEK: 'kr'
}

const SHOPPING_CART_MILEAGE = {
  /** @type {'KM'} */
  KM: 'KM',
  /** @type {'MI'} */
  MI: 'MI'
}

const SHOPPING_CART_MILEAGE_SYMBOLS = {
  /** @type {'km'} */
  KM: 'km',
  /** @type {'mi'} */
  MI: 'mi'
}

module.exports = {
  DEAL_EVENTS_JOB_QUEUES,
  SHOPPING_CART_CURRENCY,
  SHOPPING_CART_CURRENCY_SYMBOLS,
  SHOPPING_CART_MILEAGE,
  SHOPPING_CART_MILEAGE_SYMBOLS
}
