import { createAsyncThunk } from "@reduxjs/toolkit"
import type {
  SaveDealParams,
  SaveDealSuccess
} from "../../server/controllers/api/deal/saveController"
import type { TradeInInfo } from "../slices/tradeinCar"
import type { DealFinancing } from "../slices/deal"
import type { ThunkConfig } from "../store"

export const saveDealRemote = createAsyncThunk<
  SaveDealSuccess["data"],
  { dealId: string; tradeInCar?: TradeInInfo; financing?: DealFinancing },
  ThunkConfig
>(
  "deal/saveDealRemote",
  async ({ dealId, tradeInCar, financing }, { rejectWithValue, extra }) => {
    const { api } = extra()
    const params = makeSaveDealParams(tradeInCar, financing)
    const result = await api.post<SaveDealParams, SaveDealSuccess>(
      `/api/deal/${dealId}/save`,
      params
    )

    if (api.isError(result)) {
      return rejectWithValue(result.error)
    }

    return result.data.data
  }
)

export function makeSaveDealParams(
  tradeInCar?: TradeInInfo,
  financing?: DealFinancing
): SaveDealParams {
  return {
    tradeInCar: tradeInCar && {
      licensePlate: tradeInCar.licensePlate,
      mileage: tradeInCar.mileage,
      windshieldCondition: tradeInCar.windshieldCondition,
      additionalInfo: tradeInCar.additionalInfo,
      summerTires: tradeInCar.summerTires,
      winterTires: tradeInCar.winterTires,
      loanAmount: tradeInCar.loanAmount,
      loanType: tradeInCar.loanType,
      imgFront: tradeInCar.imgFront,
      imgBack: tradeInCar.imgBack,
      imgRight: tradeInCar.imgRight,
      imgLeft: tradeInCar.imgLeft,
      imgWinterTires: tradeInCar.imgWinterTires,
      imgSummerTires: tradeInCar.imgSummerTires,
      imgServiceBook: tradeInCar.imgServiceBook,
      imgAdditional1: tradeInCar.imgAdditional1,
      imgAdditional2: tradeInCar.imgAdditional2
    },
    financing: financing && {
      state: financing.state
    }
  }
}
