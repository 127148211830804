import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit"
import type { AppState, ThunkConfig } from "../store"
import type { TradeInJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/tradeIn/types"
import { saveDealRemote } from "../actions/saveDealRemote"
import type { SaveDealSuccess } from "../../server/controllers/api/deal/saveController"
import { notifyApiError } from "./notification"
import { saveTradeInCar } from "./userStatus"
import { ONBOARDING_STATUS } from "./deal"
import { updateDealOnboarding } from "../actions/updateDealOnboarding"

export interface TradeInInfo {
  // _id is only present when the trade-in car has been saved
  _id: string | null
  licensePlate: string
  mileage: number
  windshieldCondition: string
  additionalInfo: string
  summerTires: string
  winterTires: string
  loanType: string
  loanAmount: number
  imgFront: string
  imgBack: string
  imgRight: string
  imgLeft: string
  imgWinterTires: string
  imgSummerTires: string
  imgServiceBook: string
  imgAdditional1: string
  imgAdditional2: string
}

export const HAS_TRADE_IN_STATUS = {
  UNSET: "UNSET",
  CHECKED: "CHECKED",
  UNCHECKED: "UNCHECKED"
} as const

export type HasTradeInStatus =
  (typeof HAS_TRADE_IN_STATUS)[keyof typeof HAS_TRADE_IN_STATUS]

interface TradeinCarSlice {
  hasTradeInStatus: HasTradeInStatus
  tradeinInfo: TradeInInfo
  hasChanged: boolean
  isImageUploading: boolean
}

const initialState: TradeinCarSlice = {
  hasTradeInStatus: HAS_TRADE_IN_STATUS.UNSET,
  tradeinInfo: {
    _id: null,
    licensePlate: "",
    mileage: 0,
    windshieldCondition: "",
    additionalInfo: "",
    summerTires: "",
    winterTires: "",
    loanType: "",
    loanAmount: 0,
    imgFront: "",
    imgBack: "",
    imgRight: "",
    imgLeft: "",
    imgWinterTires: "",
    imgSummerTires: "",
    imgServiceBook: "",
    imgAdditional1: "",
    imgAdditional2: ""
  },
  hasChanged: false,
  isImageUploading: false
}

export type TradeInCarImageName =
  | "imgFront"
  | "imgBack"
  | "imgRight"
  | "imgLeft"
  | "imgWinterTires"
  | "imgSummerTires"
  | "imgServiceBook"
  | "imgAdditional1"
  | "imgAdditional2"

export const uploadDealTradeInCarImage = createAsyncThunk<
  string,
  // NOTE: imageName is used when updating state
  { blob: Blob; imageName: TradeInCarImageName },
  ThunkConfig
>(
  "tradeinCar/uploadImage",
  async ({ blob }, { dispatch, rejectWithValue, extra }) => {
    const { api } = extra()

    const formData = new FormData()
    formData.append("image", blob)

    const result = await api.postForm<{ url: string }>(
      "/api/upload/image",
      formData
    )

    if (api.isError(result)) {
      dispatch(notifyApiError(result.error))
      return rejectWithValue(result.error)
    }

    return result.data.url
  }
)

export const tradeinSlice = createSlice({
  name: "tradeinCar",
  initialState,
  reducers: {
    setTradeInInfo(state, action: PayloadAction<TradeInJSON>) {
      state.hasTradeInStatus = HAS_TRADE_IN_STATUS.CHECKED
      state.tradeinInfo = mapTradeIn(action.payload)
    },
    setHasTradeInStatus(state, action: PayloadAction<HasTradeInStatus>) {
      state.hasTradeInStatus = action.payload
    },
    updateHasTradeInStatus(state, action: PayloadAction<HasTradeInStatus>) {
      state.hasTradeInStatus = action.payload
      state.hasChanged = true
    },
    updateLicensePlate(state, action: PayloadAction<string>) {
      state.tradeinInfo.licensePlate = action.payload
      state.hasChanged = true
    },
    updateMileage(state, action: PayloadAction<number>) {
      state.tradeinInfo.mileage = action.payload
      state.hasChanged = true
    },
    updateWindshieldCondition(state, action: PayloadAction<string>) {
      state.tradeinInfo.windshieldCondition = action.payload
      state.hasChanged = true
    },
    updateAdditionalInfo(state, action: PayloadAction<string>) {
      state.tradeinInfo.additionalInfo = action.payload
      state.hasChanged = true
    },
    updateSummerTires(state, action: PayloadAction<string>) {
      state.tradeinInfo.summerTires = action.payload
      state.hasChanged = true
    },
    updateWinterTires(state, action: PayloadAction<string>) {
      state.tradeinInfo.winterTires = action.payload
      state.hasChanged = true
    },
    updateLoanType(state, action: PayloadAction<string>) {
      state.tradeinInfo.loanType = action.payload
      state.hasChanged = true
    },
    updateLoanAmount(state, action: PayloadAction<number>) {
      state.tradeinInfo.loanAmount = action.payload
      state.hasChanged = true
    },
    clearDealTradeInCarImage(
      state,
      action: PayloadAction<TradeInCarImageName>
    ) {
      if (state.tradeinInfo[action.payload]) {
        state.tradeinInfo[action.payload] = ""
        state.hasChanged = true
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(
      saveDealRemote.fulfilled,
      (state, action: PayloadAction<SaveDealSuccess["data"]>) => {
        if (action.payload.tradeInCar) {
          state.tradeinInfo = mapTradeIn(action.payload.tradeInCar)
        } else {
          state.tradeinInfo = { ...initialState.tradeinInfo }
        }

        state.hasTradeInStatus = action.payload.tradeInCar
          ? HAS_TRADE_IN_STATUS.CHECKED
          : HAS_TRADE_IN_STATUS.UNCHECKED

        state.hasChanged = false
      }
    )

    builder.addCase(updateDealOnboarding.fulfilled, (state, action) => {
      if (action.payload.deal.onboardingStatus === ONBOARDING_STATUS.TRADEIN) {
        state.hasTradeInStatus = HAS_TRADE_IN_STATUS.CHECKED
      }
    })

    builder.addCase(uploadDealTradeInCarImage.pending, (state) => {
      state.isImageUploading = true
    })

    builder.addCase(uploadDealTradeInCarImage.rejected, (state) => {
      state.isImageUploading = false
    })

    builder.addCase(uploadDealTradeInCarImage.fulfilled, (state, action) => {
      state.isImageUploading = false
      state.tradeinInfo[action.meta.arg.imageName] = action.payload
      state.hasChanged = true
    })

    builder.addCase(saveTradeInCar.fulfilled, (state, action) => {
      if (state.tradeinInfo._id === action.payload._id) {
        state.tradeinInfo = mapTradeIn(action.payload)
      }
    })
  }
})

/**
 * Map serialized TradeIn document to tradeinInfo state
 */
export function mapTradeIn(tradeIn: TradeInJSON): TradeInInfo {
  const loanAmount = Number(tradeIn.loanAmount)
  return {
    _id: tradeIn._id,
    additionalInfo: tradeIn.additionalInfo ?? "",
    mileage: tradeIn.mileage ?? 0,
    licensePlate: tradeIn.licensePlate ?? "",
    loanType: tradeIn.loanType ?? "",
    summerTires: tradeIn.summerTires ?? "",
    windshieldCondition: tradeIn.windshieldCondition ?? "",
    winterTires: tradeIn.winterTires ?? "",
    loanAmount: isNaN(loanAmount) ? 0 : loanAmount,
    imgFront: tradeIn.imgFront ?? "",
    imgBack: tradeIn.imgBack ?? "",
    imgRight: tradeIn.imgRight ?? "",
    imgLeft: tradeIn.imgLeft ?? "",
    imgWinterTires: tradeIn.imgWinterTires ?? "",
    imgSummerTires: tradeIn.imgSummerTires ?? "",
    imgServiceBook: tradeIn.imgServiceBook ?? "",
    imgAdditional1: tradeIn.imgAdditional1 ?? "",
    imgAdditional2: tradeIn.imgAdditional2 ?? ""
  }
}

export const {
  setTradeInInfo,
  setHasTradeInStatus,
  updateHasTradeInStatus,
  updateLicensePlate,
  updateMileage,
  updateWindshieldCondition,
  updateAdditionalInfo,
  updateSummerTires,
  updateWinterTires,
  updateLoanType,
  updateLoanAmount,
  clearDealTradeInCarImage
} = tradeinSlice.actions

export const getTradeinCarSlice = (state: AppState) => state.tradeinCar

export const selectHasTradeInStatus = createSelector(
  getTradeinCarSlice,
  (tradeinCar) => tradeinCar.hasTradeInStatus
)

export const selectTradeinInfo = createSelector(
  getTradeinCarSlice,
  (tradeinCar) => tradeinCar.tradeinInfo
)

export const selectHasChanged = createSelector(
  getTradeinCarSlice,
  (tradeinCar) => tradeinCar.hasChanged
)

export const selectImageUploading = createSelector(
  getTradeinCarSlice,
  (tradeinCar) => tradeinCar.isImageUploading
)

export const selectLicensePlateMissing = createSelector(
  getTradeinCarSlice,
  (tradeinCar) =>
    tradeinCar.hasTradeInStatus === HAS_TRADE_IN_STATUS.CHECKED &&
    !tradeinCar.tradeinInfo.licensePlate.trim()
)

export default tradeinSlice.reducer
